import Head from 'next/head'
import Image from 'next/image'
import React from 'react'

import { FormattedMessage } from 'react-intl'

import Button from '@/components/Button'

const NotFoundPage = () => {
  return (
    <div className="flex flex-auto flex-col pb-10 pt-10">
      <Head>
        <title>{`404 — Asterbit`}</title>
      </Head>
      <div className="m-auto flex w-full flex-col items-center gap-6 tablet:gap-10 desktop:flex-row-reverse desktop:justify-between">
        <div className="w-full max-w-[547px] flex-none desktop:w-1/2">
          <Image
            className="block h-auto w-full"
            alt="404 Logo"
            height={290}
            priority
            src="/images/not-found-dog.svg"
            width={547}
          />
        </div>
        <div className="flex flex-col items-center text-center desktop:max-w-[436px] desktop:items-start desktop:text-left">
          <h1 className="tablet:leading-4xl mb-5 text-xl font-medium leading-xl tablet:mb-6 tablet:text-4xl desktop:mb-8">
            <FormattedMessage
              {...{
                id: 'page.404.title',
                defaultMessage: 'Такой страницы нет'
              }}
            />
          </h1>
          <p className="mb-6 text-x-base leading-x-base tablet:mb-8 tablet:text-x-md tablet:leading-x-md desktop:text-xl desktop:font-medium desktop:leading-xl">
            <FormattedMessage
              {...{
                id: 'page.404.subtitle',
                defaultMessage:
                  'Вероятно, эта страница больше не существует {br} или у нее поменялся адрес',
                values: {
                  br: <br />
                }
              }}
            />
          </p>
          <Button
            className="min-h-11 tablet:min-h-[50px]"
            href="/"
            tag="a"
            variant="secondary"
          >
            <FormattedMessage
              {...{
                id: 'page.404.button',
                defaultMessage: 'Вернуться на главную'
              }}
            />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage
